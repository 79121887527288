import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <>
        {/* <h4>
          <Link to="/">{title}</Link>
        </h4>
        <small>
          My formula for greatness in a human being is amor fati: that one wants
          nothing to be different, not forward, not backward, not in all
          eternity. Not merely bear what is necessary, still less conceal it—all
          idealism is mendacity in the face of what is necessary—but love it.
        </small> */}
      </>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        <small>© Copyright {new Date().getFullYear()}</small>
        <br />
        <small>
          sashank<span style={{ display: "none" }}>22ygaffj</span>
          2012{" "}
          <span>
            <i> at </i>{" "}
          </span>
          gmail{" "}
          <span>
            <i> dot </i>{" "}
          </span>
          com
        </small>
      </footer>
    </div>
  )
}

export default Layout
